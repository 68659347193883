@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background-color: #f6f6f9 !important;
  color: #373737 !important;
}

/* General Style */
.load-gif {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-gif img {
  height: 20px;
}
/* end General Style */

/* Style for Navbar Section */
.kdr-navbar {
  background-color: #f6f6f9 !important;
}
.kdr-navbar-brand img {
  height: 50px;
}
.kdr-navbar-brand span {
  color: #373737;
  font-weight: 600;
}
/* end Style for Navbar Section */

/* Style for Hero Section */
.kdr-hero-section {
  padding: 50px 0;
}
.kdr-hero-section h1 {
  font-size: 80px;
  font-weight: 600;
}
.kdr-hero-section img {
  border-radius: 60px 20px 60px 20px;
}
.kdr-hero-section p {
  margin-top: 30px;
}
.kdr-hero-section a {
  background-color: #015f83;
  color: #ffffff;
  text-decoration: none;
  padding: 3px 14px;
  border-radius: 5px;
  box-shadow: 3px 3px #373737;
}
.kdr-hero-section a:hover {
  padding: 5px 16px;
  box-shadow: 0px 0px #373737;
}
/* end Style for Hero Section */

/* Style for About Me Section */
.about-me-section {
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  padding: 30px 0;
  /* opacity: 0; */
}
.about-me-section h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.about-me-section img {
  width: 80%;
}
.about-me-section p {
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
}
/* end Style for About Me Section */

/* Styie for Characteristic Section */
.kdr-characteristic-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* opacity: 0; */
}
.kdr-characteristic-item {
  width: 30%;
  text-align: center;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 30px;
  border-radius: 20px;
}
.kdr-characteristic-item img {
  height: 80px;
  margin: 20px 0;
}
.kdr-characteristic-item:hover {
  cursor: pointer;
}
/* end Styie for Characteristic Section */

/* Style for Product Section */
.kdr-product-section {
  border-radius: 20px;
  width: 100%;
  padding: 30px 0;
  background-color: #ffffff;
  /* opacity: 0; */
}
.kdr-product-section h3 {
  font-weight: 600;
}
.kdr-product-section-img-container {
  padding: 20px;
}
.kdr-product-section-img-container img {
  width: 100%;
  border-radius: 50px 0 50px 0;
}
.kdr-product-section-description-container {
  padding: 20px;
  text-align: justify;
}
/* end Style for Product Section */

/* Style for Footer Section */
.kdr-footer-section {
  margin: 20px 0;
  padding: 30px 0;
  width: 100%;
  background-color: #373737;
  border-radius: 20px;
  color: #ffffff;
}
/* end Style for Footer Section */

/* Style for Parter Section */
.logo-slider img {
  width: auto;
  height: 150px;
  display: block;
  margin: 0 auto;
}

.kdr-partner-section {
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  margin: 30px 0;
  padding-top: 30px;
  /* opacity: 0; */
}
.kdr-partner-section h3 {
  font-weight: 600;
  text-align: center;
}
.kdr-partner-box {
  background-color: #ffffff;
  height: 200px;
  margin: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.kdr-partner-box img {
  height: 150px;
}
/* end Style for Parter Section */

/* Style for No Page Section */
.kdr-no-page {
  border-radius: 20px;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
}
.kdr-no-page img {
  width: 50%;
}
/* end Style for No Page Section */

/* Mobile and Table Preview */
@media only screen and (max-width: 991px) {
  /* Responsive for Navbar Section */
  .kdr-navbar-brand span {
    display: none;
  }
  /* Responsive for Hero or Header Section */
  .kdr-hero-section {
    padding: 0;
  }
  .kdr-hero-section h1 {
    font-size: 50px;
  }
  .kdr-hero-section h3 {
    font-size: 20px;
  }
  .kdr-hero-section img {
    display: none;
  }
  .kdr-hero-section-image-container {
    padding: 0 !important;
  }
  /* Responsive for About Me Section */
  .about-me-section img {
    height: 100px;
    width: auto;
    margin-bottom: 40px;
  }
  .about-me-section-image-container {
    text-align: center;
  }
  /* Responsive for Characteristic Section */
  .kdr-characteristic-group {
    display: block;
  }
  .kdr-characteristic-item {
    width: 100%;
  }
  /* Responsive for Product Section */
  .kdr-product-section-reverse {
    flex-direction: column-reverse !important;
  }
  /* Responsive for Partner Section */
  #kdr-partner-box-2,
  #kdr-partner-box-3 {
    display: none;
  }
  /* Responsive for Footer Section */
  .kdr-footer-section h3 {
    margin-top: 15px !important;
  }
  .kdr-footer-section-box-item {
    padding: 10px !important;
    margin: 15px 0;
  }
  /* Responsive for No Page Section */
  .kdr-no-page img {
    width: 100%;
  }
}
